/* eslint-disable gestalt/no-spread-props */
import type { ToastProps } from "gestalt";
import { Box, Flex, Layer, Toast } from "gestalt";

export const BottomToast = (props: ToastProps) => {
  return (
    <Layer zIndex={{ index: () => 999999 }}>
      <Box
        dangerouslySetInlineStyle={{
          __style: {
            bottom: 50,
            left: "50%",
            transform: "translateX(-50%)",
          },
        }}
        fit
        position="fixed"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Toast
            {...props}
            //@ts-ignore
            dismissButton={props.dissmissButton}
          />
        </Flex>
      </Box>
    </Layer>
  );
};
